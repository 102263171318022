import React from 'react'
import { Link, graphql } from 'gatsby'
import { Fade, Zoom } from 'react-awesome-reveal'
import { motion } from 'framer-motion'

import { Settings, DollarSign, Award } from 'react-feather'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import FeatureArray from '../components/FeatureArray'
import Testimonials from '../components/Testimonials'
import FooterNote from '../components/FooterNote'

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  company,
  title,
  subtitle,
  featuredImage,
  section1,
  section2,
  testimonials,
  featureArray,
}) => (
  <main className="Home">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transform={{ duration: 1 }}
    >
      <PageHeader
        large
        company={company}
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <Fade>
        <section id="about" className="section">
          <div className="container taCenter col-lg-6">
            <div className="col-lg-4 mx-auto">
              <img
                src="./images/wheat-logo-color.svg"
                className="logo"
                alt="Mass Marketing Services"
              />
            </div>
            <Content source={section1} />
          </div>
        </section>
        <section className="">
          <div className="row">
            <div className="container col-lg-6 col-md-6 mx-auto my-auto">
              <img
                src="./images/test-8.jpg"
                loading="lazy"
                className="img-fluid imgFeature"
                alt="Mass Marketing Services Trusted Partnerships Club Brokerage"
              />
            </div>
            <div className="container col-lg-5 col-md-6 my-auto mx-auto">
              <h3 className="title">Trusted Partnerships</h3>
              <p>
                For over 25 years – through trusted longstanding partnerships
                and nurturing emerging brands we have helped bring exceptional
                products to market. We are committed to excellence and being a
                respected and reliable broker to our vendors and customers.
              </p>
              <p>
                Our objective is simple… to make your company’s club aspirations
                a successful and sustainable reality.
              </p>
            </div>
          </div>
        </section>
        <section id="services" className="section">
          <div className="row">
            <div className="container taCenter col-lg-6 col-md-6 my-auto">
              <img
                src="./images/test-9.jpg"
                className="img-fluid imgFeature"
                loading="lazy"
                alt="Mass Marketing Services - Product Development, Sales and Marketing, Management and Support"
              />
            </div>
            <div className="container col-lg-5 col-md-6 my-auto order-md-first order-lg-first">
              <Content source={section2} className="p-4" />
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container col-lg-10 col-8">
            <div className="row">
              <div className="col-lg-4 mx-auto text-center py-3">
                <div className="container row">
                  <Zoom>
                    <div className="container py-4 mx-auto text-center">
                      <div className="icon my-3 mx-auto text-center">
                        <Settings size="50" color="#FFF" />
                      </div>
                      <br />
                      <h4 className="title">PRODUCT DEVELOPMENT</h4>
                      <p className="taCenter py-2">
                        We offer guidance and club industry knowledge to develop
                        club specific products.
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
              <div className="col-lg-4 mx-auto text-center py-3">
                <div className="container row">
                  <Zoom>
                    <div className="container py-4 mx-auto text-center">
                      <div className="icon my-3 mx-auto text-center">
                        <DollarSign size="50" color="#FFF" />
                      </div>
                      <br />
                      <h4 className="title">SALES &amp; MARKETING</h4>
                      <p className="taCenter py-2">
                        Together, we grow your club business to new heights
                        while ensuring your business stays&nbsp;profitable.
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
              <div className="col-lg-4 text-center py-3">
                <div className="container">
                  <Zoom>
                    <div className="container mx-auto text-center">
                      <div className="icon my-3 mx-auto text-center">
                        <Award size="50" color="#FFF" />
                      </div>
                      <br />
                      <h4 className="title">MANAGEMENT &amp;&nbsp;SUPPORT</h4>
                      <p className="taCenter py-2">
                        Our in-house support team works with manufacturers to
                        verify order, pricing, program info &amp; ensure
                        on-time&nbsp;delivery.
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
          <div className="container text-center my-auto pt-5">
            <Link
              to="/services/"
              className="Button"
              aria-label="Our Club Services"
            >
              Our Club Services
            </Link>
          </div>
        </section>
        <Testimonials images={testimonials} />
        <section id="team" className="section">
          <div className="container">
            <div className="taCenter">
              <h3 className="title">Meet our Team</h3>
            </div>
            <FeatureArray images={featureArray} />
          </div>
        </section>
        <FooterNote />
      </Fade>
    </motion.div>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...testimonials
      ...featureArray
      html
      frontmatter {
        company
        title
        subtitle
        featuredImage
        section1
        section2
      }
    }
  }
`
