import React from 'react'
import PropTypes from 'prop-types'
import './Form.css'
import './FooterNote.css'
import FormSimple from '../components/FormSimple'

const FooterNote = ({ title }) => {
  return (
    <section className="section FooterNote">
      <div className="FooterNote-Container mx-auto">
        <div className="row mx-auto">
          <div className="col-lg-6 FooterNote--Img--Container">
            <div className="FooterNote--Img"></div>
          </div>
          <div className="col-lg-6 col-md-6 col-11 mx-auto my-auto text-center">
            <div className="my-auto pt-4">
              <h3 className="text-primary">Let's Talk</h3>
              <p>We look forward to helping your club business&nbsp;grow!</p>
            </div>
            <FormSimple />
          </div>
        </div>
      </div>
    </section>
  )
}

FooterNote.propTypes = {
  title: PropTypes.string,
}

export default FooterNote
