import React from 'react'
import Popup from 'reactjs-popup'
import PropTypes from 'prop-types'
import './Modal.css'

import { X, User } from 'react-feather'

const Modal = ({ title, bio }) => {
  return (
    <Popup
      trigger={
        <button class="Popup-Button">
          <User size="20" />
        </button>
      }
      modal
      nested
      className=""
    >
      {close => (
        <div className="Modal-Box modal">
          <div className="header">
            <h3>{title}</h3>
          </div>
          <div className="content">{bio}</div>
          <div className="actions pt-2">
            <button
              className="Popup-Button"
              onClick={() => {
                console.log('modal closed ')
                close()
              }}
            >
              <X />
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  bio: PropTypes.string,
}

export default Modal
