import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import HoverCard from 'react-png-hovercard'
import './HoverCardImage.css'

const HoverCardImage = ({ title, cardImage, alt, className = '' }) => {
  return (
    <HoverCard
      front={
        <div className="Front">
          <Image
            resolutions="small"
            src={cardImage}
            alt={alt}
            style={{ objectFit: 'cover' }}
            className="img-fluid"
          />
        </div>
      }
      back={
        <div className="Back container my-auto">
          <p>{title}</p>
        </div>
      }
      className={'HoverCardImage--Back'}
      maxWidth={200}
      animationSpeed={600}
      height={200}
      margin={0}
    />
  )
}

HoverCardImage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cardImage: PropTypes.string,
}

export default HoverCardImage
