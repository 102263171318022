import React from "react";
import PropTypes from "prop-types";

import Image from "./Image";
import Content from "./Content";
import "./PageHeader.css";

const PageHeader = ({
  company,
  title,
  subtitle,
  backgroundImage,
  large,
  className = "",
}) => {
  if (large) className += " PageHeader-large";
  return (
    <div className={`PageHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="PageHeader--Text container relative taCenter">
        <h1 className="PageHeader--Title">{company}</h1>
        <h2 className="PageHeader--Tagline">{title}</h2>
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  company: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;
