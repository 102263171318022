import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Image from './Image'
import HoverCardImage from './HoverCardImage'

import './Testimonials.css'

export const query = graphql`
  fragment testimonials on MarkdownRemark {
    frontmatter {
      testimonials {
        alt
        image
        quote
      }
    }
  }
`

export default class Testimonials extends Component {
  state = {
    loaded: false,
    isOpen: false,
    sliderImages: [],
    index: 0,
  }

  isOpen(isOpen, index) {
    if (typeof index === 'undefined') index = 0
    this.setState({ isOpen, index })
  }

  getImageInfo = (img, index) =>
    fetch(img.image + '-/json/')
      .then(res => res.json())
      .then(
        result => {
          const newImagesArr = [...this.state.sliderImages]
          newImagesArr[index] = {
            src: img.image,
            title: img.title,
            subtitle: img.subtitle,
            w: result.width,
            h: result.height,
          }
          this.setState({
            sliderImages: newImagesArr,
          })
          return true
        },
        error => {
          console.log(error)
          return false
        }
      )

  componentDidMount() {
    const { images } = this.props,
      maxCount = images.length
    let loopCount = 1

    for (let i in images) {
      if (this.getImageInfo(images[i], i)) {
        this.setState({ loaded: loopCount === maxCount })
        loopCount++
      }
    }
  }

  render() {
    const { images } = this.props
    return (
      <Fragment>
        {images && images.length > 0 && (
          <div className="Testimonials">
            <div className="container">
              <div className="row">
                {images.map((image, index) => (
                  <figure className="Testimonials--Item col-lg-2 col-4 text-center mx-auto my-auto">
                    <HoverCardImage
                      cardImage={image.image}
                      title={image.quote}
                      alt={image.alt}
                    />
                  </figure>
                ))}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

Testimonials.propTypes = {
  images: PropTypes.array.isRequired,
}
