import React from 'react'
import './globalStyles.css'
import './Form.css'

export default ({
  name = 'MMS Inquiry',
  subject = 'MMS Inquiry', // optional subject of the notification email
  action = '',
}) => (
  <form
    className="Form"
    name={name}
    action={action}
    data-netlify=""
    data-netlify-honeypot="_gotcha"
  >
    <input type="hidden" name="form-name" value="contact" />
    <label className="Form--Label">
      <input
        className="Form--Input Form--InputText"
        type="text"
        placeholder="Name*"
        name="firstname"
        required
      />
      <span>Name</span>
    </label>

    <label className="Form--Label">
      <input
        className="Form--Input Form--InputText"
        type="email"
        placeholder="Email*"
        name="emailAddress"
        required
      />
      <span>your@email.com</span>
    </label>

    <label className="Form--Label">
      <input
        className="Form--Input Form--InputText"
        inputMode="numeric"
        pattern="[0-9]*"
        placeholder="Phone"
        maxLength="11"
        name="phone"
        type="tel"
        required
      />
      <span>### ### ####</span>
    </label>

    <label className="Form--Label">
      <textarea
        className="Form--Input Form--InputText"
        type="text"
        placeholder="Message"
        name="message"
        required
        rows="4"
      />
      <span>Message</span>
    </label>

    <input type="text" name="_gotcha" style={{ display: 'none' }} />
    {!!subject && <input type="hidden" name="subject" value={subject} />}
    <input type="hidden" name="form-name" value={name} />
    <input
      className="Button Form--SubmitButton"
      type="submit"
      value="Contact Us"
    />
  </form>
)
